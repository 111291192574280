"use client"
import { useSearchParams } from "next/navigation";
import { BlogPost } from "@/types/blog";
import Link from "next/link";

const POSTS_PER_PAGE = 30;

type props = {
  posts: BlogPost[];
}

export default function BlogListPage({posts} : props) {
  const searchParams = useSearchParams();
  const page = searchParams.get("page");
  const currentPage = page ? parseInt(page, 10) : 1;
  const totalPages = Math.ceil(posts.length / POSTS_PER_PAGE);
  const paginatedPosts = posts.slice((currentPage - 1) * POSTS_PER_PAGE, currentPage * POSTS_PER_PAGE);

  return (
    <div className="max-w-4xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6">ブログ一覧</h1>

      {/* 2列レイアウト */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {paginatedPosts.map((post) => (
          <div key={post.slug} className="border rounded-lg shadow-md p-4 hover:shadow-lg transition">
            <h2 className="text-lg font-semibold">{post.title}</h2>
            <p className="text-gray-500 text-sm mb-2">{post.date}</p>
            <p className="text-gray-700">{post.excerpt}</p>
            <Link href={`/blog/${post.slug}`} className="text-blue-500 hover:underline mt-2 inline-block">
              もっと見る →
            </Link>
          </div>
        ))}
      </div>

      {/* ページネーション */}
      <div className="mt-6 flex justify-center space-x-4">
        {currentPage > 1 && (
          <Link href={`/blog?page=${currentPage - 1}`} className="px-4 py-2 bg-gray-300 rounded">前へ</Link>
        )}
        {currentPage < totalPages && (
          <Link href={`/blog?page=${currentPage + 1}`} className="px-4 py-2 bg-gray-300 rounded">次へ</Link>
        )}
      </div>
    </div>
  );
}